import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import axios from "axios";
import API from "../../API/config";
import {saveInLocal} from "../../utils/LocalStorageService";
import {useAppContext} from "../../context/AppContext";

export interface LoginFormData {
  email: string;
  password: string;
  remember_me?: boolean;
}

export interface ILoginForm {
  email: string;
  password: string;
}

const KnowmeIQAuth = () => {
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const {loading, setLoading} = useAppContext();

  useEffect(() => {
    if (searchParams.get("code")) {
      getTokenAndLogin(searchParams.get("code"));
    } else {
      navigate("/");
    }
  }, []);

  const getTokenAndLogin = async (code: string | null) => {
    if (!code || !process.env.REACT_APP_TOKEN_URL) {
      return;
    }
    setLoading(true);
    const tokenData = await getTokenData(code, process.env.REACT_APP_TOKEN_URL);
    await signIn(tokenData);
    setLoading(false);
    navigate("/dashboard", {replace: true});
  };

  const getTokenData = async (code: string, tokenUrl: string) => {
    const body = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      code: code,
      grant_type: "authorization_code",
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    };

    const resp = await axios.post(tokenUrl, body, {
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "AutoPylotStaging/1.0", //TODO: change it
      },
    });

    let tokenData = {
      accessToken: resp.data.access_token,
      refreshToken: resp.data.refresh_token,
      id: resp.data.id,
      // instance_url: resp.data.instance_url,
      // expires_in: resp.data.expires_in,
      scope: resp.data.scope,
      createdAt: resp.data.created_at,
      tokenType: resp.data.token_type,
    };

    const resp1 = await axios.get(
      `${process.env.REACT_APP_KNOWME_HOST}/api/users/me`,
      {
        headers: {
          Authorization: `Bearer ${tokenData.accessToken}`,
          "User-Agent": "AutoPylotStaging/1.0",
          "Content-Type": "application/vnd.api+json",
        },
      }
    );
    tokenData.id = resp1.data.data.id;
    console.log(tokenData);

    return tokenData;
  };

  const signIn = async (tokenData: any) => {
    const oauth = {
      data: {
        type: "oauths",
        attributes: {
          access_token: tokenData.accessToken,
          resource: process.env.REACT_APP_KNOWME_HOST,
          data: {
            id: tokenData.id,
            instance_url: process.env.REACT_APP_KNOWME_HOST,
          },
        },
      },
    };

    const response = await API.post("/oauth", oauth);
    await saveInLocal("token", response.data.data.id);
  };

  return <></>;
};

export default KnowmeIQAuth;
