import styled from "@emotion/styled";
import {drawerWidth} from "../Sidebar/styledComponents";

interface StyledProps {
  isSidebarOpen: boolean;
  sidebarHidden?: boolean;
  isPublicPage: boolean;
}
export const Container = styled.div`
  min-height: calc(100vh - 39px);
  background-color: ${({sidebarHidden}: StyledProps) =>
    sidebarHidden ? "#fff" : "#F0F2F5"};
  margin-left: ${({isSidebarOpen, isPublicPage}: StyledProps) =>
    isPublicPage ? 0 : isSidebarOpen ? `${drawerWidth}px` : "3.6em"};
  transition: margin 0.3s ease-in;
`;
export const ImpersonatePanel = styled.div`
  z-index: 9999;
  position: absolute;
  width: 100%;
  background: #fff;
  padding: 5px 0;
  button {
    margin-left: 5px;
  }
`;
