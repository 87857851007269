import React, {useState} from "react";

import {useNavigate, useLocation, Navigate} from "react-router-dom";
import {useForm} from "react-hook-form";

import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";

import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircleIcon from "@mui/icons-material/Circle";

import {useAppContext} from "../../context/AppContext";
import {useAuth} from "../../hooks/authHook";
import Snackbar from "../../components/CustomSnackbar/CustomSnackbar";
import {loginUser} from "../../services/login";
import {saveInLocal} from "../../utils/LocalStorageService";

import PublicLayout from "../../components/PublicLayout/PublicLayout";

import knowmeIQImg from "./knowmeIQ.png";

import {
  StyledTextField,
  Row,
  ButtonRow,
  LoginButton,
  StyledLink,
  LoginPaper,
  ContentWrapper,
  StyledFormControlLabel,
  StyledIconButton,
  FooterRow,
} from "./styled";

export interface LoginFormData {
  email: string;
  password: string;
  remember_me?: boolean;
}

export interface ILoginForm {
  email: string;
  password: string;
}

interface CustomizedState {
  userEmail: string;
}

interface CustomSnackbarProps {
  open: boolean;
  severity: "success" | "error" | "warning" | "info";
}

const Login = () => {
  let navigate = useNavigate();
  const {loading, setLoading} = useAppContext();
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({
    open: false,
    severity: "success",
  });
  const location = useLocation();
  const state = location.state as CustomizedState;
  const userEmail = state?.userEmail;
  const {isAuthenticated} = useAuth();

  const {getValues, register, handleSubmit, formState} = useForm<LoginFormData>(
    {
      defaultValues: {email: userEmail, password: "", remember_me: false},
    }
  );

  const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>) => {
    setSnackbarProps((state) => ({...state, open: false}));
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  const forgotPassword = () => {
    let data = getValues();
    navigate("/forgot-password", {state: {userEmail: data.email}});
  };

  const loginWithKnowmeIQ = () => {
    console.log("env", process.env);

    console.log("REACT_APP_AUTH_URL", process.env.REACT_APP_AUTH_URL);
    console.log("REDIRECT_URI", process.env.REACT_APP_REDIRECT_URI);

    const redirectURI = encodeURIComponent(
      process.env.REACT_APP_REDIRECT_URI || ""
    );
    const authURL = `${process.env.REACT_APP_AUTH_URL}?scope=api%20refresh_token%20public&client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${redirectURI}`;

    window.open(authURL, "_self");
  };

  const onSubmitUser = async (data: ILoginForm) => {
    try {
      setLoading(true);
      const response = await loginUser({
        email: data.email,
        password: data.password.trim(),
      });
      saveInLocal("token", response.data.token);
      if (response.status !== 200) {
        return setMessage("Error");
      }
      setLoading(false);
      navigate("/dashboard", {replace: true});
    } catch (error: any) {
      setLoading(false);
      if (error.response.status === 401) {
        setMessage("Email or password not valid");
      } else {
        setMessage("Server Error");
      }

      setSnackbarProps({severity: "error", open: true});
    }
  };

  if (isAuthenticated) {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <PublicLayout hideFooter={true}>
      <LoginPaper elevation={0}>
        <ContentWrapper>
          <form onSubmit={handleSubmit(onSubmitUser)}>
            <Row>
              <label>Email address</label>
              <StyledTextField
                {...register("email", {required: "Required Field"})}
                inputProps={{
                  autoComplete: "off",
                  form: {
                    autoComplete: "off",
                  },
                }}
                variant="outlined"
                size="small"
                error={!!formState.errors?.email}
                helperText={formState.errors?.email?.message}
              />
            </Row>
            <Row>
              <label>Password</label>
              <StyledTextField
                {...register("password", {required: "Required Field"})}
                inputProps={{
                  autoComplete: "off",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledIconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </StyledIconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
                type={showPassword ? "text" : "password"}
                error={!!formState.errors?.password}
                helperText={formState.errors?.password?.message}
              />
            </Row>
            <Row className="remember-me">
              <StyledFormControlLabel
                control={<Checkbox {...register("remember_me")} />}
                label="Remember Me"
              />
              <StyledLink href="#" underline="always" onClick={forgotPassword}>
                Forgot Password?
              </StyledLink>
            </Row>
            <ButtonRow style={{marginBottom: "20px"}}>
              {loading ? (
                <CircularProgress />
              ) : (
                <LoginButton type="submit">Log in</LoginButton>
              )}
            </ButtonRow>
            <ButtonRow style={{marginBottom: "20px"}}>
              {loading ? (
                <CircularProgress />
              ) : (
                <LoginButton
                  style={{
                    background: "#fff",
                    color: "#EF681E",
                    border: "1px solid #EF681E",
                  }}
                  type="button"
                  onClick={loginWithKnowmeIQ}
                >
                  <img
                    style={{position: "absolute", left: "5px", width: "30px"}}
                    src={knowmeIQImg}
                    alt="logo"
                  />
                  Log in with KnowMe IQ
                </LoginButton>
              )}
            </ButtonRow>
          </form>
          <div>
            <div className="signup-link">
              Don’t have an account yet?{" "}
              <StyledLink href="/signup" underline="always">
                Sign up for free.
              </StyledLink>
            </div>
          </div>
        </ContentWrapper>
      </LoginPaper>
      <FooterRow>
        <div>Copyright @ AutoPylot Technologies Corp. 2024</div>
        <div>
          <StyledLink
            href="https://www.autopylot.com/privacy"
            target={"_blank"}
          >
            Privacy
          </StyledLink>
          <CircleIcon />
          <StyledLink href="https://www.autopylot.com/terms" target={"_blank"}>
            Terms
          </StyledLink>
        </div>
      </FooterRow>
      <Snackbar
        open={snackbarProps.open}
        message={message}
        severity={snackbarProps.severity}
        autoHideDuration={6000}
        onClose={handleClose}
      />
    </PublicLayout>
  );
};

export default Login;
