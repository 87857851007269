import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";

import {useStores} from "../stores/rootStore";
import {Account} from "../stores/AccountStore";
import {User} from "../stores/UserStore";
import {useAuth} from "../hooks/authHook";

interface IAppContext {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  openSidebar: boolean;
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  refreshCurrentUser: () => void;
  isAdmin: boolean | null;
  isMasterAdmin: boolean | null;
  account: Account | null;
  currentUser: User | null;
}

export const AppContext = createContext<IAppContext | undefined>(undefined);

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [loading, setLoading] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const {userInfo, isAuthenticated} = useAuth();
  const {userStore} = useStores();

  const isAdmin = useMemo(() => !!currentUser?.isAdmin, [currentUser]);
  const isMasterAdmin = useMemo(
    () => !!currentUser?.isMasterAdmin,
    [currentUser]
  );
  const account = useMemo(() => currentUser?.account ?? null, [currentUser]);

  useEffect(() => {
    setCurrentUser(null);
    const getCurrentUser = async () => {
      await userStore.getUserAsync(
        userInfo.id,
        "roles,did_allocations.did,account,account.subscription.plan"
      );
      setCurrentUser(userStore.user);
    };

    if (isAuthenticated) {
      if (userInfo.id !== 0) {
        //&& !currentUser) {
        getCurrentUser();
      }
    } else {
      setCurrentUser(null);
    }
  }, [userInfo.id]);

  const refreshCurrentUser = async () => {
    await currentUser?.reload(
      "roles,did_allocations.did,account,account.subscription.plan"
    );
    setCurrentUser(currentUser?.store?.user);
  };

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        openSidebar,
        setOpenSidebar,
        isAdmin,
        isMasterAdmin,
        account,
        currentUser,
        refreshCurrentUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): IAppContext => {
  const context: IAppContext | undefined = useContext(AppContext);
  if (context === undefined) throw Error("Error with Context");
  return context;
};
